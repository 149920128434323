<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("PERSONAL_INFO") }}</h1>
    </div>
    <b-alert variant="success" show v-if="!getOnlineUser.password_changed_at">
      Merci de modifier votre mot de passe
    </b-alert>
    <b-form
      @submit.prevent="update"
      v-if="!getOnlineUserLoading && form"
      class="content"
    >
      <div class="pageBlock">
        <div class="left">
          <div class="image" v-if="!form.image">
            <div class="profil" v-if="form.nom && form.prenom">
              <span>{{ form.prenom.slice(0, 1) }}</span>
              <span>{{ form.nom.slice(0, 1) }}</span>
            </div>
          </div>

          <h3>{{ form.prenom }} {{ form.nom }}</h3>
          <h4 v-if="form.role.slice(5) == 'entreprise-admin'">
            Administrateur
          </h4>
          <h4 v-else>{{ form.role.slice(5) }}</h4>
        </div>
        <div class="right">
          <h2>{{ $t("INFORMATIONS") }}</h2>
          <div class="rightF">
            <b-form-group :label="$t('SURNAME')">
              <b-form-input
                v-model="form.prenom"
                :placeholder="$t('SURNAME')"
                class="label-form"
                required
              ></b-form-input>
              <div v-if="erreurlist.prenom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.prenom)">
                  <span v-for="(e, index) in erreurlist.prenom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.prenom }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('NAME')">
              <b-form-input
                v-model="form.nom"
                :placeholder="$t('NAME')"
                class="label-form"
                required
              ></b-form-input>
              <div v-if="erreurlist.nom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.nom)">
                  <span v-for="(e, index) in erreurlist.nom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.nom }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('PHONE')" class="w-100">
              <template>
                <phone
                  :tel="form.phone"
                  @phoneNumber="formatNumber($event)"
                ></phone>
              </template>
            </b-form-group>
            <div v-if="erreurlist.phone" class="error-message">
              <ul v-if="Array.isArray(erreurlist.phone)">
                <span v-for="(e, index) in erreurlist.phone" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.phone }}</span>
            </div>
            <b-alert
              variant="warning"
              show
              v-if="numberError == false"
              class="w-100"
            >
              {{ $t("ERROR_PHONE") }}
            </b-alert>
            <b-form-group :label="$t('EMAIL')" class="w-100">
              <b-form-input
                v-model="form.email_u"
                autocomplete="off"
                type="email"
                :placeholder="$t('EMAIL')"
                class="label-form"
                required
                readonly
              ></b-form-input>
            </b-form-group>
          </div>

          <h2>{{ $t("SECURITY") }}</h2>
          <div class="rightF">
            <b-form-group :label="$t('PASSWORD')" class="label d-block">
              <b-form-input
                v-model="form.password"
                autocomplete="off"
                :placeholder="$t('PASSWORD')"
                class="label-form"
                type="password"
              ></b-form-input>
              <div v-if="erreurlist.password" class="error-message">
                <ul v-if="Array.isArray(erreurlist.password)">
                  <span v-for="(e, index) in erreurlist.password" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.password }}</span>
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('PASSWORD_CONFIRM')"
              class="label d-block"
              v-if="form.password"
            >
              <b-form-input
                v-model="form.password_confirmation"
                :placeholder="$t('PASSWORD_CONFIRM')"
                class="label-form"
                autocomplete="off"
                type="password"
                required
              ></b-form-input>
              <div
                v-if="erreurlist.password_confirmation"
                class="error-message"
              >
                <ul v-if="Array.isArray(erreurlist.password_confirmation)">
                  <span
                    v-for="(e, index) in erreurlist.password_confirmation"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.password_confirmation }}</span>
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('PASSWORD_CONFIRM')"
              class="label d-block"
              v-else
            >
              <b-form-input
                v-model="form.password_confirmation"
                :placeholder="$t('PASSWORD_CONFIRM')"
                class="label-form"
                autocomplete="false"
                type="password"
              ></b-form-input>
              <div
                v-if="erreurlist.password_confirmation"
                class="error-message"
              >
                <ul v-if="Array.isArray(erreurlist.password_confirmation)">
                  <span
                    v-for="(e, index) in erreurlist.password_confirmation"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.password_confirmation }}</span>
              </div>
            </b-form-group>
          </div>

          <div class="submit">
            <p v-if="error">{{ $t("ERROR") }}</p>
            <b-button type="submit" variant="primary">
              <font-awesome-icon icon="check" />
              {{ $t("SAVE") }}
            </b-button>
          </div>
        </div>
      </div>
    </b-form>

    <b-form v-else>
      <b-spinner class="load" label="Spinning"> </b-spinner>
    </b-form>

    <b-modal id="Modaleinfo" ref="Modaleinfo">
      <template #modal-header="{ close }">
        <h5>{{ $t("NOTIF") }}</h5>
        <b-button size="sm" @click="close()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
      <div class="modalinfo">
        <p class="my-4">{{ $t("UPDATE_SUCCESS") }}</p>
      </div>
      <template #modal-footer="{ close }">
        <div class="double">
          <b-button variant="danger" @click="close">
            <div class="block-spinner">
              {{ $t("OK") }}
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>

    <CommentMarche to="/how-it-work/infoPersonnelle"></CommentMarche>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { domains } from "@/environment";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import phone from "../components/ui/phone.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    phone,
    CommentMarche,
  },
  data() {
    return {
      form: null,
      imageyUrl: domains.download,
      error: null,
      numberError: null,
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
      erreurlist: {
        prenom: null,
        nom: null,
        phone: null,
        email_u: null,
        password: null,
        password_confirmation: null,
      },
    };
  },

  methods: {
    ...mapActions(["updateUser", "pays", "onlineUser"]),

    async update() {
      if (this.numberError == false) {
        return;
      }
      this.erreurlist = {
        prenom: null,
        nom: null,
        phone: null,
        email_u: null,
        password: null,
        password_confirmation: null,
      };
      this.error = null;
      // Format numero tel
      var num = this.form.phone.phone_number.indexOf(" ");
      this.form.phone = {
        phone_number: this.form.phone.phone_number
          .slice(num + 1)
          .split(" ")
          .join(""),
        phone_iso_code: this.form.phone.phone_iso_code
          ? this.form.phone.phone_iso_code
          : this.getOnlineUser.phone.iso_code,
      };

      var bodyFormData = new FormData();
      if (this.form.password && this.form.password_confirmation) {
        bodyFormData.append("nom", this.form.nom);
        bodyFormData.append("prenom", this.form.prenom);
        bodyFormData.append("email_u", this.form.email_u);
        bodyFormData.append("tel_u", this.form.phone.phone_number);
        bodyFormData.append("phone_number", this.form.phone.phone_number);
        bodyFormData.append("phone_iso_code", this.form.phone.phone_iso_code);
        bodyFormData.append("adresse", this.form.adresse);
        bodyFormData.append("ville", this.form.ville);
        bodyFormData.append("cp", this.form.cp);
        bodyFormData.append("pays", this.form.pays);

        if (this.form.file) bodyFormData.append("image", this.form.file);
        else bodyFormData.append("image", this.form.image);

        bodyFormData.append("password", this.form.password);
        bodyFormData.append(
          "password_confirmation",
          this.form.password_confirmation
        );
      } else {
        bodyFormData.append("nom", this.form.nom);
        bodyFormData.append("prenom", this.form.prenom);
        bodyFormData.append("email_u", this.form.email_u);
        bodyFormData.append("tel_u", this.form.phone.phone_number);
        bodyFormData.append("phone_number", this.form.phone.phone_number);
        bodyFormData.append("phone_iso_code", this.form.phone.phone_iso_code);
        bodyFormData.append("adresse", this.form.adresse);
        bodyFormData.append("ville", this.form.ville);
        bodyFormData.append("cp", this.form.cp);
        bodyFormData.append("pays", this.form.pays);

        if (this.form.file) bodyFormData.append("image", this.form.file);
        else bodyFormData.append("image", this.form.image);
      }
      await this.updateUser(bodyFormData)
        .then(() => {
          this.$refs["Modaleinfo"].show();
          this.resultsExample = { tel: null, valid: false };
          this.onlineUser().then(() => {
            this.form = { ...this.getOnlineUser };
          });
          this.error = null;
        })
        .catch((err) => {
          for (const [key, value] of Object.entries(err)) {
            if (!this.erreurlist[key]) {
              this.erreurlist[key] = value;
            } else {
              this.error = err;
            }
          }
        });
    },

    formatNumber(event) {
      this.form.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.numberError = event.isValid;
    },
  },
  mounted() {
    this.onlineUser();
    this.form = { ...this.getOnlineUser };
  },
  computed: {
    ...mapGetters([
      "getOnlineUser",
      "getOnlineUserLoading",
      "getTabs",
      "getPays",
      "getSelectedLang",
      "getdictionnaire",
    ]),
  },

  beforeRouteLeave(to, from, next) {
    if (this.getOnlineUser)
      if (!this.getOnlineUser.password_changed_at) {
        alert("Veuillez modifier votre Mot de passe !");
        return;
      }
    next();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  h1 {
    width: 100%;
  }
}
.container {
  @media only screen and (max-width: 750px) {
    width: 80%;
  }
  width: 95%;
  padding: 0px 35px 20px;
}

.alert-success {
  position: relative;
  top: -5px;
  width: 97%;
  text-align: center;
  background-color: #34c38f;
  margin: 0 auto;
}

form {
  margin-top: 48px;
  background-color: #f5f6f8 !important;
  box-shadow: none !important;
  padding: 0 !important;

  hr {
    @media only screen and (max-width: 900px) {
      width: 90%;
    }
    border-top: 1px solid #707070;
    margin: 2rem auto;
    width: 60%;
  }

  .pageBlock {
    @media only screen and (max-width: 900px) {
      display: block;
    }
    display: flex;
    justify-content: space-between;
    .left {
      @media only screen and (max-width: 900px) {
        width: 100%;
        min-height: auto;
      }
      min-height: 83vh;
      padding: 8px;
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      box-shadow: 1px 1px 24px #00000019;

      h3 {
        text-align: center;
        color: #393939;
        font-size: 24px;
        font-weight: 800;
        margin: 16px 0 8px;
      }

      label {
        cursor: pointer;
        font-size: 12px;
        color: #5370ff;
        margin: 16px 0 20px;

        &:hover {
          text-decoration: underline;
        }
      }

      #file_input {
        display: none;
      }

      h4 {
        margin: 0;
        color: #393939;
        font-size: 16px;
        font-style: italic;
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: center;

        .profil {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background-color: #28367a;
          color: #fff;
          font-size: 58px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }

    .right {
      @media only screen and (max-width: 900px) {
        width: 100%;
        margin-top: 15px;
      }
      position: relative;
      padding: 24px;
      background-color: #fff;
      box-shadow: 1px 1px 24px #00000019;
      width: 66%;

      h2 {
        width: 100%;
        font-size: 16px;
        font-weight: 800;
      }
    }

    .rightF {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      fieldset {
        width: 48%;
        margin: 0 0 15px;
        color: #28367a;
        font-size: 12px;

        input {
          padding: 0 16px;
          font-size: 14px;
        }
      }
    }
  }
}

.submit {
  @media only screen and (max-width: 900px) {
    position: relative;
    bottom: 0;
  }
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 0px;
  right: 16px;
  bottom: 16px;

  p {
    position: absolute;
    top: 0;
    color: #e4261b;
    margin: 0;
    margin-right: 32px;
    font-size: 12px;
  }

  .btn {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #34c38f;
    border-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    width: 35%;
    height: 42px;

    svg {
      margin-right: 10px;
      height: 18px;
      width: 18px;
    }
  }
}
.block-spinner {
  display: flex;
  justify-content: center;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}
.modalinfo {
  p {
    text-align: center;
    font-size: 21px;
  }
}
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}

.load {
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: 50%;
}
</style>
